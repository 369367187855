const list = {
    "A": [
        "阿尔巴尼亚 +355",
        "阿尔及利亚 +213",
        "阿富汗 +93",
        "阿根廷 +54",
        "阿拉伯联合酋长国 +971",
        "阿鲁巴岛 +297",
        "阿曼 +968",
        "阿塞拜疆 +994",
        "阿森松 +247",
        "埃及 +20",
        "埃塞俄比亚 +251",
        "爱尔兰 +353",
        "爱沙尼亚 +372",
        "安道尔 +376",
        "安哥拉 +244",
        "安圭拉 +1264",
        "安提瓜和巴布达 +1268",
        "奥地利 +43",
        "奥兰群岛 +358",
        "澳大利亚 +61",
        "中国澳门 +853"
    ],
    "B": [
        "巴巴多斯 +1246",
        "巴哈马 +1242",
        "巴基斯坦 +92",
        "巴拉圭 +595",
        "巴勒斯坦 +970",
        "巴林 +973",
        "巴拿马 +507",
        "巴西 +55",
        "白俄罗斯 +375",
        "百慕大 +1441",
        "保加利亚 +359",
        "北马里亚纳群岛 +1670",
        "贝宁 +229",
        "比利时 +32",
        "冰岛 +354",
        "波多黎各 +1787",
        "波黑 +387",
        "波兰 +48",
        "玻利维亚 +591",
        "伯利兹 +501",
        "博茨瓦纳 +267",
        "不丹 +975",
        "布基拉法索 +226",
        "布隆迪 +257"
    ]
    ,
    "C": [
        "朝鲜 +850",
        "赤道几内亚 +240"
    ],
    "D": [
        "丹麦 +45",
        "德国 +49",
        "多哥 +228",
        "多米尼加共和国 +1809",
        "多米尼克 +1767"
    ],
    "E": [
        "俄罗斯 +7",
        "厄瓜多尔 +593",
        "厄立特里亚 +291"
    ],
    "F": [
        "法国 +33",
        "法罗群岛 +298",
        "法属波利尼西亚 +689",
        "法属圭亚那 +594",
        "梵蒂冈 +379",
        "菲律宾 +63",
        "斐济 +679",
        "芬兰 +358",
        "佛得角 +238"
    ],
    "G": [
        "冈比亚 +220",
        "刚果 +242",
        "刚果民主共和国 +243",
        "哥伦比亚 +57",
        "哥斯达黎加 +506",
        "格林纳达 +1473",
        "格陵兰岛 +299",
        "格鲁吉亚 +995",
        "瓜德罗普 +590",
        "关岛 +1671",
        "圭亚那 +592"
    ],
    "H": [
        "海地 +509",
        "韩国 +82",
        "荷兰 +31",
        "荷属安的列斯 +599",
        "洪都拉斯 +504"
    ],
    "J": [
        "吉布提 +253",
        "加拿大 +1",
        "吉尔吉斯斯坦 +996",
        "几内亚 +224",
        "加那利群岛 +3491",
        "加纳 +233",
        "加蓬 +241",
        "柬埔寨 +855",
        "捷克 +420"
    ],
    "K": [
        "喀麦隆 +237",
        "卡塔尔 +974",
        "开曼群岛 +1345",
        "科摩罗 +269",
        "科索沃 +883",
        "科特迪瓦 +225",
        "科威特 +965",
        "克罗地亚 +385",
        "肯尼亚 +254"
    ],
    "L": [
        "拉脱维亚 +371",
        "莱索托 +266",
        "老挝 +856",
        "黎巴嫩 +961",
        "立陶宛 +370",
        "利比里亚 +231",
        "利比亚 +218",
        "列支敦士登 +423",
        "留尼旺岛 +262",
        "卢森堡 +352",
        "卢旺达 +250",
        "罗马尼亚 +40"
    ],
    "M": [
        "马达加斯加 +261",
        "马尔代夫 +960",
        "马耳他 +356",
        "马拉维 +265",
        "马来西亚 +60",
        "马里 +223",
        "马其顿 +389",
        "马绍尔群岛 +692",
        "马提尼克 +596",
        "马约特 +262",
        "毛里求斯 +230",
        "毛里塔尼亚 +222",
        "美国 +1",
        "美属萨摩亚 +1684",
        "美属维尔京群岛 +1340",
        "蒙古 +976",
        "蒙塞拉特岛 +1664",
        "蒙特內哥羅 +382",
        "孟加拉国 +880",
        "秘鲁 +51",
        "密克罗尼西亚联邦 +691",
        "缅甸 +95",
        "摩尔多瓦 +373",
        "摩洛哥 +212",
        "摩纳哥 +377",
        "莫桑比克 +258",
        "墨西哥 +52"
    ],
    "N": [
        "纳米比亚 +264",
        "南非 +27",
        "尼泊尔 +977",
        "尼加拉瓜 +505",
        "尼日尔 +227",
        "尼日利亚 +234",
        "挪威 +47"
    ],
    "P": [
        "帕劳 +680",
        "葡萄牙 +351"
    ],
    "Q": [
        "千里达及托巴哥 +1868"
    ],
    "R": [
        "日本 +81",
        "瑞典 +46",
        "瑞士 +41"
    ],
    "S": [
        "圣诞岛 +61",
        "圣基茨和尼维斯 +1869",
        "圣卢西亚 +1758",
        "圣马力诺 +223",
        "圣马力诺 +378",
        "圣皮埃尔和密克隆群岛 +508",
        "圣文森特和格林纳丁斯 +1784",
        "斯里兰卡 +94",
        "斯洛伐克 +421",
        "斯洛文尼亚 +386",
        "斯威士兰 +268",
        "苏丹 +249",
        "苏里南 +597",
        "索马里 +252",
        "塔吉克斯坦 +992",
        "沙特阿拉伯 +966",
        "塞舌尔 +248",
        "塞浦路斯 +357",
        "塞内加尔 +221",
        "塞拉利昂 +232",
        "塞尔维亚 +381",
        "萨尔瓦多 +503",
        "萨尔瓦多 +503"
    ],

    "T": [
        "汤加 +676",
        "坦桑尼亚 +255",
        "泰国 +66",
        "中国台湾 +886",
        "特克斯和凯科斯群岛 +1649",
        "突尼斯 +216",
        "图瓦卢 +688",
        "土耳其 +90",
        "土库曼斯坦 +993"
    ],
    "W": [
        "乌兹别克斯坦 +998",
        "乌拉圭 +598",
        "乌克兰 +380",
        "乌干达 +256",
        "文莱 +673",
        "委内瑞拉 +58",
        "危地马拉 +502"
    ],

    "X": [
        "叙利亚 +963",
        "匈牙利 +36",
        "新西兰 +64",
        "新喀里多尼亚 +687",
        "新加坡 +65",
        "中国香港 +852",
        "希腊 +30",
        "西萨摩亚 +685",
        "西撒哈拉 +212",
        "西班牙 +34"
    ],

    "Y": [
        "越南 +84",
        "约旦 +962",
        "英属维尔京群岛 +1284",
        "英国 +44",
        "印度尼西亚 +62",
        "印度 +91",
        "意大利 +39",
        "以色列 +972",
        "伊朗 +98",
        "伊拉克 +964",
        "也门 +967",
        "亚美尼亚 +374",
        "牙买加 +1876"
    ],

    "Z": [
        "中国 +86",
        "中非 +236",
        "智利 +56",
        "直布罗陀 +350",
        "乍得 +235",
        "赞比亚 +260"
    ],


    // "热门": [
    //     "中国 +86",
    //     "中国香港 +852",
    //     "中国澳门 +853",
    //     "中国台湾 +886"
    // ],

};

const newlist = Object.values(list).reduce(
    (prev, next) => prev.concat(next),
    []
);
// list.forEach(i=>i={i.split(" ")[0]:i.split(" ")[0]})
export const phoneCodeList = newlist.map((i) => ({
    country: i.split(" ")[0],
    code: i.split(" ")[1],
}));