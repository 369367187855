<template>
	<div class="login_wrap">
		<div class="content">
			<div class="leftAd"></div>

			<div class="right">
				<div class="tab">
					<div class="current">企业老板登录</div>
					<div @click="go_company_login">企业员工登录</div>
				</div>

				<div class="middle_line">
					<div class="middle_p_left">手机号</div>
					<a-input-group compact>
						<a-input style="height: 50px" placeholder="输入手机号" v-model="phone" @pressEnter="go_login">
							<a-select slot="addonBefore" :default-value="codeIndex" v-model="codeIndex" :dropdownMatchSelectWidth="false" @change="changeCode">
								<a-select-option style="min-width: 150px" v-for="(item, index) in phoneCodeList" :key="index" :value="index">
									{{ `${item.country}(${item.code})` }}
								</a-select-option>
							</a-select>
						</a-input>
					</a-input-group>
				</div>

				<div class="middle_password_line" v-show="type == 3">
					<div class="middle_p_left">密码：</div>
					<a-input-password class="pass" placeholder="输入密码" v-model="password" @pressEnter="go_login" />
				</div>

				<div class="middle_password_line" v-show="type == 1">
					<div class="middle_p_left">验证码：</div>
					<div class="middle_password_content">
						<a-input placeholder="请输入验证码" v-model="messagecode" @pressEnter="go_login" />
						<a-button type="text" class="yanzheq" :loading="get_messagecode_state" @click="get_messagecode"> 获取验证码 </a-button>
					</div>
				</div>

				<!-- <div class="middle_down" v-show="type == 3">
          <a-checkbox
            class="middle_d_left"
            :checked="checked"
            @change="rember_password"
          >
            记住密码
          </a-checkbox>
        </div> -->

				<a-button type="primary" class="down_btn" @click="go_login"> 登录 </a-button>

				<div class="bottom">
					<div class="bottomleft">
						<div v-if="type !== 1" class="top_left" @click="change_type(1)">验证码登录</div>
						<div v-if="type !== 3" class="top_left" @click="change_type(3)">个人密码登录</div>
					</div>

					<div class="jump" @click="go_register">跳转注册</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { user_login, user_send_sms } from "@/api/login";
import { phoneCodeList } from "@/utils/phoneCode.js";
export default {
	data() {
		return {
			type: 1,
			codeIndex: 0,
			code: "86",
			phoneCodeList: phoneCodeList,
			phone: "",
			password: "",
			messagecode: null,
			get_messagecode_state: false,
			checked: true, //是否记住密码

			register_code: "+86",
		};
	},
	mounted() {
		if (localStorage.user_password) {
			this.password = localStorage.user_password;
			this.phone = localStorage.user_phone;
		}
		this.codeIndex = this.phoneCodeList.findIndex(item => item.code === "+86");
	},
	methods: {
		changeCode(value) {
			this.code = this.phoneCodeList[value].code.slice(1);
		},
		go_register() {
			this.$router.push({ name: "login_register" });
		},
		go_company_login() {
			this.$router.push({ name: "login_company" });
		},
		change_type(data) {
			this.type = data;
		},
		async get_messagecode() {
			if (this.get_messagecode_state) {
				return;
			}
			let appid = "2006686160";

			let captcha1 = new TencentCaptcha(appid, res => {
				if (res.ret === 0) {
					this.get_messagecode_state = true;
					user_send_sms({
						type: 2,
						phone: this.phone,
						ty_ticket: res.ticket,
						ty_rand_str: res.randstr,
						code: this.code,
					}).then(res => {
						if (res.data.code == 200) {
							this.$message.success("验证码已发出,60秒之后可再次点击获取");

							setTimeout(() => {
								this.get_messagecode_state = false;
							}, 60000);
						} else {
							setTimeout(() => {
								this.get_messagecode_state = false;
							}, 3000);
						}
					});
				}
			});
			captcha1.show(); // 显示验证码
		},
		rember_password(e) {
			this.checked = e.target.checked;
		},
		async go_login() {
			// if (!~navigator.userAgent.indexOf("YangTao")) {
			//   alert("登录参数错误~");
			//   return false;
			// }

			if (this.type == 3) {
				if (this.checked) {
					localStorage.user_password = this.password;
					localStorage.user_phone = this.phone;
				} else {
					localStorage.user_password = "";
				}
			}

			let { data } = await user_login({
				phone: this.phone,
				type: this.type,
				verify_code: this.messagecode,
				password: this.password,
				register_code: this.code,
			});
			if (data.code == 200) {
				localStorage.member = JSON.stringify(data.data.member);
				localStorage.user = JSON.stringify(data.data.user);
				localStorage.token = data.data.token;
				// localStorage.isLogin = data.data.member.is_first_login;
				let params = {
					message: "/connection/send_configdata",
					token: data.data.token,
				};
				this.$store.state.websocket.send(this.$encrypt(JSON.stringify(params)));
				this.$router.push({ name: "manage_home" });
			} else {
				setTimeout(() => {
					this.get_messagecode_state = false;
				}, 3000);
			}
		},
	},
};
</script>

<style scoped lang="less">
.login_wrap .content .right .middle_password_line .middle_password_content button.yanzheq {
	color: #4c84ff;
}
.login_wrap {
	// background: #919191;
	background: url(../../assets/img/backnew.png) no-repeat center center;
	background-size: 100% 100%;
	margin-left: 40px;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;

	.content {
		display: flex;
		width: 920px;
		height: 540px;

		.leftAd {
			display: flex;
			background: url(../.././/assets/img/leftad.png) no-repeat center center;
			background-size: 100% 100%;
			width: 320px;
			height: 540px;
		}

		.right {
			display: flex;
			flex: 1;
			flex-direction: column;
			background: #fff;
			padding-left: 44px;

			.tab {
				display: flex;
				margin-top: 50px;

				div {
					margin-right: 60px;
					font-size: 22px;
					font-weight: 500;
					color: #374567;
					position: relative;
					cursor: pointer;

					&.current {
						color: #3b77fa;

						&::after {
							position: absolute;
							content: "";
							bottom: -5px;
							left: 0;
							height: 2px;
							background: #3b77fa;
							width: 100%;
						}
					}
				}
			}

			.bottom {
				display: flex;
				margin-top: 46px;
				width: 500px;
				justify-content: space-between;

				.top_left {
					font-size: 16px;
					font-weight: 400;
					color: #3b77fa;
					cursor: pointer;

					&:hover {
						color: #4c84ff;
					}
				}

				.jump {
					font-size: 16px;
					font-weight: 400;
					color: #546581;
					line-height: 22px;
					cursor: pointer;
				}
			}

			.middle_line {
				display: flex;
				width: 100%;
				margin-top: 37px;
				flex-direction: column;

				.middle_p_left {
					flex: none;
				}

				.ant-input-group {
					width: 500px;
					margin-top: 12px;

					input {
						width: 500px !important;
						height: 50px;
						background: #f8fbff;
						border-radius: 2px;
						border: 0;
					}
				}
			}

			.middle_password_line {
				display: flex;
				width: 100%;
				margin-top: 20px;
				flex-direction: column;

				.middle_p_left {
					flex: none;
				}

				.pass {
					display: flex;
					width: 500px;
					height: 50px;
					border-radius: 2px;
					background: #f8fbff;
					border: 0;
					margin-top: 12px;
					justify-content: center;
					align-items: center;

					::v-deep {
						input {
							border: 0;
							background: none;
						}
					}
				}

				.middle_password_content {
					width: 500px;
					background: #f8fbff;
					display: flex;
					margin-top: 12px;
					justify-content: center;
					align-items: center;

					input {
						display: flex;
						flex: 1;
						height: 50px;
						border-radius: 2px;
						background: #f8fbff;
						border: 0;
					}

					button {
						width: 122px;
						background: none;
						font-size: 14px;
						font-weight: 400;
						color: #bebebe;
						line-height: 20px;
						border: 0;
						height: 50px;
						box-shadow: none;
						position: relative;

						&::after {
							content: "";
							width: 1px;
							height: 20px;
							background: #c9ccd0;
							position: absolute;
							left: 0;
						}
					}
				}
			}

			.middle_down {
				display: flex;
				margin-top: 20px;
				justify-content: space-between;

				.middle_d_right {
					margin-right: 40px;
					color: #4c84ff;
					cursor: pointer;
				}
			}

			.down_btn {
				width: 500px;
				height: 54px;
				background: #3b77fa;
				border-radius: 2px;
				font-size: 18px;
				font-weight: 500;
				margin-top: 40px;
				color: #ffffff;
			}
		}
	}
	::v-deep .ant-input {
		height: 50px;
	}
}
</style>
